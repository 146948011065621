import React from "react"
import { StyleSheetManager } from "styled-components"
import { ThemeProvider } from "./src/context/ThemeContext"

import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/400-italic.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/ibm-plex-sans-thai/400.css"
import "@fontsource/ibm-plex-sans-thai/500.css"
import "@fontsource/ibm-plex-sans-thai/700.css"

import "antd/dist/antd.less"

// eslint-disable-next-line react/prop-types,react/display-name
const wrapRootElement = ({ element }, pluginOptions) => (
  <StyleSheetManager
    disableVendorPrefixes={pluginOptions?.disableVendorPrefixes === true}
  >
    {element}
  </StyleSheetManager>
)

const wrapPageElement = ({ element, props }) => {
  return <ThemeProvider {...props}>{element}</ThemeProvider>
}

export { wrapRootElement, wrapPageElement }

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
